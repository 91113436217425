import React from 'react'
import Logo from '../../assets/images/logo.png';
import './index.scss';

const Navbar = () => {
    return (
        <div className="navbar_main_container">
            <div className="navbar_container container" data-aos="fade-in" data-aos-duration="1000">
                <div className="left_container">
                    <a href='#home' rel="noreferrer" className="logo_container">
                        <img src={Logo} className="logo" alt="logo" />
                    </a>
                </div>
                <div className="right_container">
                    <div className='right-menu'>
                        <a href='#infrastructure' rel="noreferrer">
                            infrastructure
                        </a>
                        <span className='docs-link'>
                            docs
                            <div className="tooltip">COMING SOON</div>
                        </span>
                    </div>
                    <a className='btn_main' href="#getintouch" rel="noreferrer">Get in Touch</a>
                </div>
            </div>
        </div>
    )
}

export default Navbar