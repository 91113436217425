import React, { useState, useEffect } from 'react';
import Navbar from './components/Navbar';
import AOS from 'aos';
import HomePage from './container/HomePage';
import { Routes, Route } from "react-router-dom";
import Lottie from 'react-lottie';
import AnimatedCursor from "react-animated-cursor"
import 'aos/dist/aos.css';
import './App.scss';
import Footer from './components/Footer';
import { Provider as AlertProvider, positions } from 'react-alert';
import CustomAlertTemplate from './container/HomePage/CustomAlertTemplate';

import mouseImg from './assets/images/mouse.png';
import * as animationData from './assets/images/loader.json';

function App() {
  AOS.init();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendrendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 800);
  }, []);

  if (isLoading) {
    return <div className="loader-modal">
      <Lottie options={defaultOptions}
        height={200}
        width={200}
        isClickToPauseDisabled={true}
      />
    </div>;
  }
  const transitions = {
    FADE: 'fade',
    SCALE: 'scale'
  }
  const alertOptions = {
    timeout: 5000,
    position: positions.TOP_RIGHT,
    // offset: '60px 20px',
    transition: transitions.SCALE
  };


  return (
    <>
      <AlertProvider template={CustomAlertTemplate} {...alertOptions}>
        <AnimatedCursor innerScale={0.2} outerSize={5} style={{ cursor: `url${mouseImg}` }} />
        <Navbar />
        <Routes>
          <Route path="/" element={<HomePage />} />
        </Routes>
        <Footer />
      </AlertProvider>
    </>
  );
}

export default App;