import React from 'react'
import logoMain from '../../assets/images/logo.png';
import './index.scss';

const Footer = () => {
    return (
        <section className='footer-main'>
            <div className='container'>
                <div className='footer-upper'>
                    <div className='left-upper'>
                        <img src={logoMain} alt='beragio' />
                        <p>ZK-Enabled Fun-Hub.</p>
                    </div>
                    <div className='right-upper'>
                        <a href='https://twitter.com/beragiogames' target='_blank' rel="noreferrer">
                            <svg width="21" height="20" viewBox="0 0 21 20">
                                <path d="M21 19.9421C19.0013 19.9513 17.0027 19.9577 15.004 19.9724C14.7091 19.9743 14.5804 19.7739 14.4361 19.58C12.7242 17.2715 11.014 14.9621 9.303 12.6536C8.97978 12.2171 8.9743 12.2116 8.60726 12.6104C6.53191 14.8711 4.431 17.1098 2.40678 19.4164C1.88543 20.0101 1.32391 19.9761 0.699391 19.9669C0.484826 19.9632 0.167087 20.091 0.0766957 19.8704C-0.0109565 19.6572 0.291261 19.5037 0.439174 19.342C2.89891 16.6494 5.35683 13.9567 7.83209 11.2797C8.11604 10.9728 7.93435 10.8009 7.77365 10.584C5.31026 7.26095 2.84596 3.93971 0.383478 0.616643C0.239217 0.421817 0.0748696 0.238938 0 0H6.36391C6.45796 0.138768 6.54652 0.280292 6.64604 0.414465C8.19365 2.50517 9.74217 4.59495 11.2898 6.68474C11.6167 7.12677 11.6203 7.12953 12.0065 6.71047C14.0663 4.47457 16.1234 2.23682 18.1823 0C18.8187 0 19.4551 0 20.0915 0C19.9336 0.203097 19.7884 0.418141 19.6158 0.607453C17.3597 3.07035 15.1036 5.53416 12.8328 7.98327C12.5662 8.27092 12.5863 8.45564 12.8091 8.75339C14.4863 10.9902 16.1444 13.2417 17.8117 15.4868C18.8717 16.9131 19.9372 18.3357 21 19.7601V19.943V19.9421ZM18.0983 18.4855C18.0828 18.2723 17.9587 18.1721 17.871 18.0536C16.6603 16.415 15.4478 14.7783 14.2343 13.1416C11.456 9.39117 8.67391 5.64352 5.90374 1.88669C5.68187 1.58618 5.44813 1.44465 5.07287 1.46487C4.54422 1.49244 4.01283 1.46671 3.48326 1.47498C3.32439 1.47774 3.10526 1.39227 3.02217 1.55953C2.93909 1.72678 3.1363 1.8536 3.23035 1.98134C5.26461 4.73097 7.30343 7.47783 9.33952 10.2265C11.2761 12.8411 13.2117 15.4565 15.1465 18.072C15.3108 18.2944 15.4642 18.5002 15.7947 18.4919C16.5498 18.4736 17.3058 18.4864 18.0974 18.4864L18.0983 18.4855Z" />
                            </svg>
                        </a>
                    </div>
                </div>
            </div>
            <div className="copyright">
                © 2024 Beragio | All rights reserved
            </div>
        </section>
    )
}

export default Footer