import React from 'react';
import { crossIcon } from '../../utils/icons';

const CustomAlertTemplate = ({ style, options, message, close }) => {
  return (
    <div className="custom_alert_main_container">
      <div
        style={{
          padding: '10px',
          borderRadius: '5px',
          marginBottom: '10px',
          ...style
        }}
        className="custom_alert_container"
      >
        {message}
        <button onClick={close} >
          {/* ✖ */}
          <div className="close_icon">
          <div dangerouslySetInnerHTML={{ __html: crossIcon }} />
          </div>
        </button>
      </div>
    </div>
  );
};

export default CustomAlertTemplate;
